@font-face {
  font-family: "Sofia Pro Black";
  src: local('Sofia Pro Black'), url(./fonts/SofiaBlackRegular.woff2) format('woff2');
} 

body {
  font-family: "Sofia Pro Black", 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #272930;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.collapsible.filters-collapsible {
  margin-top: 30px;
}

.input-field.col label.basic-filter-input-label {
  top: -22px;
}

.chips.common-tags .input {
  width: 140px !important;
}

.date-filter > div {
  display: inline-block;
  padding-right: 8px;
  margin: 0px 0px 6px 0px;
}

.input-field.year-select {
  max-width: 68px;
}

.input-field.month-select {
  max-width: 110px;
}

.date-filter .select-wrapper input.select-dropdown {
  border: none;
  margin: 0;
}


.collapsible-body {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.collapsible-body .row {
  margin: 0;
  padding-bottom: 6px;
}

.collapsible, .collapsible-header, .collapsible-body {
  border-color: #282c34;
}

.collapsible, .collapsible-body, .collapsible-header {
  background-color: #454c5e;
  color: #ecf1fe;
}

.tuto-chip {
  margin: 20px 0px -10px 0px;
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  background-color: #454c5e;
  color: darkgrey;
}

/* ##### CUSTOM CARD COLUMNS ##### */
.root .cards-container {
  width: none;
}
.cards-container .card {
  display: inline-block;
  overflow: visible;
}

.list {
  margin:0;
  padding: 0;
  display: table;
  width:100%;
}

.list>li {
  max-width: 270px;
  display: inline-block;
  margin: 6px;
  text-align: center;
}

.text-center {
  text-align: center;
}
/* ################# @@@@ ############### */


.cards-container {
  width:  250px;
}


/* ##### CUSTOM LOOK ON CARDS ##### */
.chips-autocomplete {
  max-height: 500px !important;
}
.autocomplete-content{
  /* top: 46px !important; */
  width: auto!important;
  height: auto!important;
  max-height: inherit !important;
  /* min-width: 360px; */
}

nav .container-fluid  .brand-logo {
  position: relative;
  padding-left: 11px;
}

.card .card-title {
  font-size: medium;
}

.game .modal, .game .modal-footer {
  border-radius: 6px;
  background-color: #454c5e;
  color: #ecf1fe;
}

.game-count {
  color: #ecf1fe;
}

.game .card-image img {
  min-height: 155px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.game.card .card-action  {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 11px;
  padding: 16px 16px;
}

.game.card .card-action .left a, .game.card .card-action .right a {
  margin: 0 !important;
}


.game.card.small {
  height: 250px;
}

.game.card {
  background-color: #454c5e;
  color: #ecf1fe;
  border-radius: 6px;
}

.game.card .card-content .card-title {
  max-height: 32px;
  overflow: hidden;
}

.game.card:hover .card-action, .game.card:hover {
  background-color: #3e434e;
}

.card .card-reveal {
  height: 63%;
  background-color: #454c5e;
  color: #ecf1fe;
}

.card .card-content {
  padding-bottom: 0px;
  padding-top: 10px;
}

.container-fluid {
  margin: 0 auto;
  /* max-width: 1280px; */
  width: 90%;
}

.nav-wrapper {
  background-color: #3b3e48;
}

input {
  color: #ecf1fe;
}
/* ################# @@@@ ############### */
